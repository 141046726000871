import { toast, ToastOptions } from 'react-toastify';
import { Toast } from 'components/shared/Toast';
import { cartStore } from 'stores/cartStore';
import { ReactNode } from 'react';

interface Props {
  type: 'success' | 'warning' | 'error';
  children?: ReactNode;
  toastProps?: ToastOptions;
}

export function ShowNotification({ type, children, toastProps }: Props) {
  const cartButtonSize = cartStore.fixedButtonSize;

  return toast(<Toast type={type}>{String(children)}</Toast>, {
    ...toastProps,
    className:
      cartButtonSize !== 120 ? 'toast-container' : 'toast-container-big',
  });
}
