import clsx from 'clsx';
import { Button } from 'components/shared/Button';
import { TooltipContainer } from 'components/shared/Tooltip/TooltipContainer';
import { Promo } from 'containers/Cart/Promo';
import { formatBalanceForLocale } from 'containers/Finances/formatBalanceForLocale';
import i18n from 'i18n/i18n';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import classes from './Total.module.scss';
import { TotalProps } from './Total.props';
import { useTotal } from './useTotal';
import { useCheckCurrency } from 'hooks/useCheckCurrency';
import { getLangName } from 'tools/utils';
import { LocalizedNames } from 'types/util-types';

export const Total = observer(
  ({
    total,
    createDeals,
    applyCouponHandler,
    coupon,
    setCoupon,
    couponError,
    setCouponError,
    withdrawCouponHandler,
    selectedItems,
    buttonRef,
    errorMessage,
  }: TotalProps) => {
    const {
      currentCurrencySymbol,
      currentCurrency,
      realDiscount,
      totalWithDiscounts,
      couponDiscount,
    } = useTotal(selectedItems, total);
    const { t } = useTranslation();
    const checkCurrency = useCheckCurrency();

    return (
      <div className={classes.wrapper}>
        <div className={classes.box}>
          <span className={classes.title}>{t('Order details')}</span>

          {selectedItems.map((item) => {
            const localeName = getLangName(item.service, 'name', i18n);
            const serviceName =
              localeName.length > 25
                ? `${localeName?.slice(0, 25)}...`
                : localeName;

            return (
              <div key={item?.service?.id} className={classes.row}>
                <span className={classes.subtitle} title={item?.service?.name}>
                  {item.service.name.length > 25 ? (
                    <TooltipContainer
                      text={localeName}
                      position={'top'}
                      className={classes.tooltipContainer}
                      classNameTooltip={classes.tooltip}
                    >
                      <div className={classes.tooltipContainer_name}>
                        {serviceName}
                      </div>
                      <span>x{item?.count}</span>
                    </TooltipContainer>
                  ) : (
                    <>
                      {serviceName}
                      <span>x{item?.count}</span>
                    </>
                  )}
                </span>

                <span className={classes.line}></span>

                <span className={classes.cost}>
                  {item.service.is_free
                    ? formatBalanceForLocale(
                        0,
                        currentCurrency,
                        currentCurrencySymbol
                      )
                    : formatBalanceForLocale(
                        item?.total_cost * item?.count,
                        currentCurrency,
                        currentCurrencySymbol
                      )}
                </span>
              </div>
            );
          })}

          {selectedItems?.length > 0 && realDiscount > 0 && (
            <div className={clsx(classes.row, classes.discount)}>
              <span className={classes.subtitle}>{t('Discount')}</span>
              <span className={classes.line}></span>
              <span className={classes.cost}>
                -
                {formatBalanceForLocale(
                  Math.abs(realDiscount),
                  currentCurrency,
                  currentCurrencySymbol
                )}
              </span>
            </div>
          )}
        </div>

        <div className={classes.create}>
          {selectedItems?.length > 0 && (
            <Promo
              applyCouponHandler={applyCouponHandler}
              coupon={coupon}
              couponError={couponError}
              errorMessage={errorMessage}
              setCouponError={setCouponError}
              withdrawCouponHandler={withdrawCouponHandler}
              couponDiscount={couponDiscount}
              totalWithDiscounts={totalWithDiscounts}
            />
          )}

          <div className={classes.total}>
            <span className={classes.subtitleBig}>{t('Total')}*</span>
            <span
              ref={buttonRef}
              className={clsx(
                classes.costBig,
                checkCurrency && classes['costBig--small']
              )}
            >
              {formatBalanceForLocale(
                Number(total) !== Number(totalWithDiscounts)
                  ? Math.ceil(Number(totalWithDiscounts))
                  : Math.ceil(Number(total)),
                currentCurrency,
                currentCurrencySymbol
              )}
            </span>
          </div>
        </div>        
        <div data-step-cart-3>
        <Button
          theme={'primary'}
          size={'big'}
          className={classes.button}
          disabled={!selectedItems.length}
          onClick={createDeals}
        >
          <span>{t('Start projects from cart')}</span>
        </Button>
        </div>

        <div className={classes.desc}>
          *{' '}
          {t(
            "This price is\u00A0preliminary. VAT is\u00A0not included. You'll get VAT and the total price calculation and terms of\u00A0deal after consultation with our sales manager. To\u00A0know more about the price please check our"
          )}
          &nbsp;
          <NavLink
            to={'/pricing-disclaimer/'}
            state={{ name: 'Cart' }}
            className={classes.disclaimer}
          >
            {t('Pricing Disclaimer')}
          </NavLink>
        </div>
      </div>
    );
  }
);
