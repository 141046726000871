import { Button } from 'components/shared/Button';
import { Grid, GridItem } from 'components/common/Grid';
import { InfoIcon } from 'components/shared/Icons/InfoIcon';
import { Input } from 'components/shared/Input';
import { MultiselectComponent } from 'components/shared/MultiselectComponent';
import { Notice } from 'components/shared//Notice';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import { TooltipContainer } from 'components/shared/Tooltip/TooltipContainer';
import { Type } from 'components/shared/Type';
import { useRef } from 'react';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import { getLinkForPrivacy } from '../getLinkForPrivacy';
import classes from './Business.module.scss';
import { BusinessProps } from './Business.props';
import { useBusiness } from './useBusiness';
import { companySizeList } from 'constants/companySizeList';
import { FormBusinessInformationValues } from 'types/forms';

export const Business = ({ defaultValue, getParams }: BusinessProps) => {
  const formRef = useRef(null);
  const {
    isFieldEmpty,
    t,
    i18n,
    handleSubmit,
    submit,
    errors,
    setCompanyName,
    companyName,
    control,
    companySize,
    setCompanyLink,
    companyLink,
    isValid,
    disableSubmit,
    setSelectedBusinessType,
    businessTypes,
    setSelectedCompanySize,
    businessType,
    notice,
  } = useBusiness(defaultValue, getParams);

  const { size, industry } = isFieldEmpty;

  return (
    <>
      <TitleAndDescription pageName="Account/business" />

      <div className={classes.wrapper}>
        <div className={classes.titleWrap}>
          <Type tag={'h2'} className={classes.title}>
            {t('Business information')}
          </Type>
        </div>

        <p className={classes['info']}>
          {t(
            'By providing Us with Your contact information You further agree on and accept the terms of our'
          )}{' '}
          <a
            className={classes['info__link']}
            href={getLinkForPrivacy(i18n.language)}
            target="_blank"
            rel="noreferrer"
          >
            {t('Privacy Policy (personal)')}
          </a>
        </p>

        <form
          className={classes.inner}
          ref={formRef}
          onSubmit={handleSubmit(submit)}
        >
          <div className={classes.box}>
            <Grid direction={'row'} className={classes.row}>
              <GridItem
                col={{ default: 12, md: 6 }}
                className={classes['input-field']}
              >
                <Controller
                  name="companyName"
                  control={control}
                  render={({
                    field,
                  }: {
                    field: ControllerRenderProps<
                      FormBusinessInformationValues,
                      'companyName'
                    >;
                  }) => (
                    <Input
                      label={t('Company Name')}
                      labelPosition={'left'}
                      type={'text'}
                      placeholder={t('Enter company name')}
                      error={!!errors.companyName}
                      value={companyName || field.value}
                      onChange={(e) => {
                        setCompanyName(e);
                        field.onChange(e);
                      }}
                    />
                  )}
                />
                {errors?.companyName && (
                  <TooltipContainer
                    text={errors.companyName?.message}
                    customClasses={'kit-ui-block'}
                    position={'top'}
                    className={classes.error}
                  >
                    <InfoIcon fill="#F5222D" width="18px" height="18px" />
                  </TooltipContainer>
                )}
              </GridItem>

              <GridItem
                col={{ default: 12, md: 6 }}
                className={classes['input-field']}
              >
                <Controller
                  name="url"
                  control={control}
                  render={({
                    field,
                  }: {
                    field: ControllerRenderProps<
                      FormBusinessInformationValues,
                      'url'
                    >;
                  }) => (
                    <Input
                      label={t('Company link')}
                      labelPosition={'left'}
                      type={'text'}
                      placeholder={t('Company link')}
                      value={field.value || companyLink}
                      error={!!errors.url}
                      onChange={(e) => {
                        setCompanyLink(e);
                        field.onChange(e);
                      }}
                    />
                  )}
                />
                {errors?.url && (
                  <TooltipContainer
                    text={errors.url?.message}
                    customClasses={'kit-ui-block'}
                    position={'top'}
                    className={classes.error}
                  >
                    <InfoIcon fill="#F5222D" width="18px" height="18px" />
                  </TooltipContainer>
                )}
              </GridItem>
            </Grid>

            <Grid direction={'row'} className={classes.row}>
              <GridItem col={{ default: 12 }}>
                <div className={classes['item-label']}>{t('Company size')}</div>
                <Controller
                  name="size"
                  control={control}
                  render={() => (
                    <MultiselectComponent
                      data={companySizeList(t)}
                      defaultSelected={companySize}
                      placeholder=""
                      setMultiselect={setSelectedCompanySize}
                      search={false}
                      isFieldEmpty={size}
                      isCompanySize
                    />
                  )}
                />
              </GridItem>
            </Grid>

            <Grid direction={'row'} className={classes.row}>
              <GridItem col={{ default: 12 }}>
                <div className={classes['item-label']}>
                  {t('Industry of the company')}
                </div>
                <Controller
                  name="industry"
                  control={control}
                  render={() => (
                    <MultiselectComponent
                      data={businessTypes}
                      defaultSelected={businessType}
                      placeholder=""
                      setMultiselect={setSelectedBusinessType}
                      search={false}
                      isFieldEmpty={industry}
                    />
                  )}
                />
              </GridItem>
            </Grid>
          </div>

          <div className={classes.submit}>
            <Button
              className={classes.button}
              theme="primary"
              disabled={disableSubmit || !isValid}
              size="middle"
              type="submit"
            >
              {t('Save changes')}
            </Button>
          </div>
        </form>

        {notice.type !== '' && (
          <div className={classes.notice}>
            <Notice type={notice.type}>{notice.text}</Notice>
          </div>
        )}
      </div>
    </>
  );
};
