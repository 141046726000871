import { makeAutoObservable, runInAction, toJS } from 'mobx';
import BasketService from 'services/basket';
import { userStore } from './userStore';
import _ from 'lodash';
import { ICart, ICartData } from 'types';
import { SolutionType } from 'types/solutions';

interface addOrRemoveCartProps {
  serviceId: number;
  count?: number;
  sourcePage?: string;
}

export interface SelectedItemProps {
  service: { id: number };
  id: number;
}

class CartStore {
  cart: ICart | null = null;
  deals = [];
  isLoading = false;
  selectedItems: ICartData[] = [];
  selectedBPS: ICartData[] = [];
  modalOpen = false;
  fixedButtonSize = 0;
  zIndex = 2;
  isSelected = false;

  constructor() {
    makeAutoObservable(this);
  }

  async handleSelectedItem(item: ICartData) {
    this.setIsSelected(true);
    const index = this.selectedItems.findIndex(
      (selectedItem) => selectedItem.service.id === item.service.id
    );
    if (index !== -1) {
      await BasketService.addToSelectedService({
        serviceId: Number(item.service.id),
        select: false,
      }).then(async (response) => {
        // let basket = await this.loadCart();
        this.selectedItems.splice(index, 1);
        this.selectedBPS = response.data.basket.basket_project_service
          ?.map((item: ICartData) => {
            if (item.selected) {
              return item;
            } else {
              return null;
            }
          })
          .filter((item: ICartData) => item !== null);
        this.setIsSelected(false);
      });
    } else {
      await BasketService.addToSelectedService({
        serviceId: Number(item.service.id),
        select: true,
      }).then(async (response) => {
        // let basket = await this.loadCart();
        this.selectedItems.push(item);
        this.selectedBPS = response.data.basket.basket_project_service
          ?.map((item: ICartData) => {
            if (item.selected) {
              return item;
            } else {
              return null;
            }
          })
          .filter((item: ICartData) => item !== null);
        this.setIsSelected(false);
      });
    }
  }

  async handleAllItemsInSelectedItem(data: ICartData[], isChecked: boolean) {
    this.setIsSelected(true);
    const servicesIds: number[] = data.map((item) => Number(item.service.id));

    if (isChecked) {
      const response = await BasketService.addToSelectedService({
        serviceIds: servicesIds,
        select: true,
      });

      data.forEach((item) => {
        if (
          !this.selectedItems.some(
            (selectedItem) => selectedItem.service.id === item.service.id
          )
        ) {
          this.selectedItems.push(item);
        }
      });

      this.selectedBPS =
        response.data.basket.basket_project_service?.filter(
          (item: ICartData) => item.selected
        ) || [];
      this.setIsSelected(false);
    } else {
      const response = await BasketService.addToSelectedService({
        serviceIds: servicesIds,
        select: false,
      });

      this.selectedItems = this.selectedItems.filter(
        (selectedItem) =>
          !data.some((item) => item.service.id === selectedItem.service.id)
      );

      this.selectedBPS =
        response.data.basket.basket_project_service?.filter(
          (item: ICartData) => item.selected
        ) || [];
      this.setIsSelected(false);
    }
  }

  handleRemoveSelected() {
    this.selectedItems.forEach(async ({ service, count }) => {
      await this.removeFromCart({ serviceId: Number(service.id), count });
    });
  }

  // TODO need to refactor backend
  updateSelectedItems(projectService: ICartData[]) {
    const projectServiceMap = new Map();

    for (const projectItem of projectService) {
      const serviceId = projectItem.service.id;
      if (!projectServiceMap.has(serviceId)) {
        projectServiceMap.set(serviceId, []);
      }
      projectServiceMap.get(serviceId).push(projectItem.id);
    }

    for (const selectedItem of this.selectedItems) {
      const serviceId = selectedItem.service.id;
      const updatedItems = projectServiceMap.get(serviceId) || [];
      selectedItem.updatedServices = updatedItems;
      selectedItem.count = updatedItems.length;
    }
  }

  addCart(cart: ICart) {
    if (cart.error) {
      this.cart = null;
    } else {
      this.cart = cart;
    }
  }

  getServiceCountInCart(serviceId: number): number {
    const cart = this.getActiveCart() || { basket_project_service: [] };
    let countInCart = 0;
    if (cart) {
      cart?.basket_project_service?.forEach((service: ICartData) => {
        if (service.service.id === serviceId) {
          countInCart += 1;
        }
      });
    }
    return countInCart;
  }

  getGroupedBasketProjectServices(cart: ICart) {
    const res = [];
    const group = _.groupBy(
      cart.basket_project_service,
      (item) => item.service.id
    );
    for (const [, services] of Object.entries(group)) {
      const { id, ...groupDataWithoutId } = services[0];
      const groupData = { ...groupDataWithoutId, count: services.length };
      res.push(groupData);
    }
    return res;
  }

  private isServiceAlreadySelected(serviceId: number): boolean {
    return this.selectedItems.some((item) => item.service.id === serviceId);
  }

  async getBasket() {
    const response = await BasketService.getBasket();
    if (response?.data?.error) {
      return [];
    }

    const cart = response?.data || {};
    if (cart) {
      cart.basket_project_service_group =
        this.getGroupedBasketProjectServices(cart);
      for (const service of cart.basket_project_service_group) {
        if (
          service.selected &&
          !this.isServiceAlreadySelected(service.service.id)
        ) {
          this.selectedItems.push(service);
        }
      }
    }

    return cart;
  }

  async loadCart() {
    this.setIsLoading(true);
    const basket = await this.getBasket();

    this.addCart(basket);
    if (this.selectedItems.length > 0) {
      this.updateSelectedItems(basket.basket_project_service);
    }
    this.setIsLoading(false);
    this.selectedBPS = basket.basket_project_service
      ?.map((item: ICartData) => {
        if (item.selected) {
          return item;
        } else {
          return null;
        }
      })
      .filter((item: ICartData) => item !== null);
    return this.cart;
  }

  setIsLoading(val: boolean) {
    this.isLoading = val;
  }

  setIsSelected(val: boolean) {
    this.isSelected = val;
  }

  getActiveCart() {
    return this.cart;
  }

  setFixedButtonSize(size: number, zIndex?: number) {
    this.fixedButtonSize = size;
    if (zIndex) this.zIndex = zIndex;
  }

  async addToCart({ serviceId, count = 1 }: addOrRemoveCartProps) {
    this.setIsLoading(true);

    const checkItemExists = (items: ICartData[], id: number): boolean =>
      items.some((item) => item.service.id === id);

    const select = checkItemExists(this.selectedItems, serviceId);

    await BasketService.addToBasket({
      serviceId: serviceId,
      sourcePage: window.location?.href,
      count,
      select: select,
    });

    const res = await Promise.all([this.loadCart(), userStore.loadUser()]);

    this.setIsLoading(false);

    return res[0];
  }

  async removeFromCart({ serviceId, count = 1 }: addOrRemoveCartProps) {
    this.setIsLoading(true);

    const checkItemExists = (items: ICartData[], id: number): boolean =>
      items.some((item) => item.service.id === id);

    const select = checkItemExists(this.selectedItems, serviceId);

    await BasketService.removeFromBasket({
      serviceId: serviceId,
      count,
      sourcePage: window.location?.href,
      select: select,
    });

    const res = await Promise.all([this.loadCart(), userStore.loadUser()]);

    this.selectedItems = this.selectedItems.filter(
      (item: ICartData) => item.count >= 1
    );

    this.setIsLoading(false);

    return res[0];
  }

  async createDeal({ serviceSourceIds }: { serviceSourceIds: number[] }) {
    this.setIsLoading(true);
    try {
      await runInAction(async () => {
        this.selectedItems = this.selectedItems.filter(
          (item: ICartData) =>
            !serviceSourceIds.some((id: number) => id !== item.service.id)
        );
      });
      await BasketService.createDeal({ serviceSourceIds }).then((response) => {
        userStore.setProjects(response?.data?.projects);
      });
      await Promise.all([this.loadCart(), userStore.loadProjects()]);
    } catch (err: unknown) {
      this.setIsLoading(false);
      throw err;
    } finally {
      this.setIsLoading(false);
    }
  }

  updateCardCost(
    total: string | number,
    selectedService: SolutionType,
    discount: number | string
  ) {
    this.selectedItems.forEach((item) => {
      if (item.service.id === selectedService.id) {
        item.total_cost = Number(total);
        item.total_cost_with_discounts = discount.toString();
        item.service = selectedService;
      }
    });
  }

  clearStore() {
    this.modalOpen = false;
    this.cart = null;
    this.deals = [];
    this.selectedItems = [];
    this.isLoading = false;
  }
}

export const cartStore = new CartStore();
