import { Request } from 'tools/request';


export async function GetDashboardProjects() {
  const result = await Request({
    type: 'GET',
    url: `${process.env.REACT_APP_API}/api/v1/dashboards/projects/`,
    isProtected: true,
  });

  return result;
}
