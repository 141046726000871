import { yupResolver } from '@hookform/resolvers/yup';
import {
  lettersCheckReg,
  numbersCheckReg,
  symbolsCheckReg,
} from 'constants/matches';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Request } from 'tools/request';
import { ShowNotification } from 'tools/showNotification';
import { FormInputsChangePassword } from 'types/auth';
import { passwordSchema } from '../validationSchemas';
import { AxiosError } from 'axios';

export const useChangePassword = () => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setError,
  } = useForm<FormInputsChangePassword>({
    resolver: yupResolver(passwordSchema(t('Passwords don’t match'), t)),
    mode: 'onChange',
  });

  const [numbersCheck, setNumbersCheck] = useState(false);
  const [symbolsCheck, setSymbolsCheck] = useState(false);
  const [letterCheck, setLetterCheck] = useState(false);
  const [showChecks, setShowChecks] = useState(false);
  const [showPassword, setShowPassword] = useState<{
    oldPass: boolean;
    newPass: boolean;
    newPassConfirmation: boolean;
  }>({
    oldPass: false,
    newPass: false,
    newPassConfirmation: false,
  });
  const [password, setPassword] = useState<string>('');
  const [passwordRepeat, setPasswordRepeat] = useState<string>('');
  const [oldPassword, setOldPassword] = useState<string>('');
  const [cookies, setCookies] = useCookies(['access', 'refresh']);
  const [disableSubmit, setDisableSubmit] = useState(false);

  useEffect(() => {
    if (numbersCheckReg.test(password)) {
      setNumbersCheck(true);
    } else {
      setNumbersCheck(false);
    }
    if (lettersCheckReg.test(password)) {
      setLetterCheck(true);
    } else {
      setLetterCheck(false);
    }
    if (symbolsCheckReg.test(password)) {
      setSymbolsCheck(true);
    } else {
      setSymbolsCheck(false);
    }
  }, [password, oldPassword, passwordRepeat]);

  const onSubmit = async (data: FormInputsChangePassword) => {
    const formValues = new FormData();

    const { oldPass, newPass } = data;

    formValues.append('old_password', oldPass);
    formValues.append('new_password', newPass);

    try {
      setDisableSubmit(true);
      await Request({
        type: 'PUT',
        url: `${process.env.REACT_APP_API}/api/v1/users/`,
        isProtected: true,
        access: cookies.access,
        data: formValues,
      });
      setDisableSubmit(false);
      ShowNotification({
        type: 'success',
        children: t('Profile successfully changed'),
      });
    } catch (error: unknown) {
      setDisableSubmit(false);

      if (error instanceof AxiosError) {
        const { old_password, new_password } = error?.response?.data || '';

        if (old_password?.length) {
          setError('oldPass', { type: 'server', message: old_password });
        }
        if (new_password?.length) {
          setError('newPass', { type: 'server', message: new_password });
        }
      }
    }
  };

  const handleShowPassword = (type: string) => {
    setShowPassword({
      ...showPassword,
      [type]: !showPassword[type as keyof typeof showPassword],
    });
  };

  return {
    showPassword,
    t,
    handleSubmit,
    control,
    errors,
    onSubmit,
    setOldPassword,
    handleShowPassword,
    showChecks,
    password,
    numbersCheck,
    symbolsCheck,
    letterCheck,
    setPassword,
    passwordRepeat,
    setPasswordRepeat,
    disableSubmit,
    isValid,
    setShowChecks,
  };
};
