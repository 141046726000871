import { AxiosError } from 'axios';
import headerClasses from 'components/common/Header/Header.module.scss';
import { useCartStore } from 'contexts/CartContext';
import { useWindowWidth } from 'hooks/useWindowWidth';
import 'intro.js/introjs.css';
import { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { ApplyCoupon } from 'services/api/applyCoupon';
import { WithdrawCoupon } from 'services/api/withdrawCoupon';
import { userStore } from 'stores/userStore';
import { ShowNotification } from 'tools/showNotification';
import { ICartData } from 'types';

export const useCart = () => {
  const cartStore = useCartStore();
  const { t } = useTranslation();
  const activeCart = cartStore.getActiveCart();

  const count = activeCart?.services_count;
  const data = activeCart?.basket_project_service_group;
  const projectServices = activeCart?.basket_project_service || [];
  const selectedItems = cartStore.selectedItems;
  const selectedBPS = cartStore.selectedBPS;

  const total = selectedBPS?.reduce((accumulator: number, item: ICartData) => {
    const realCost = item.service?.is_free ? 0 : Number(item.total_cost);
    return accumulator + realCost;
  }, 0) as number;

  const totalWithDiscounts = selectedBPS?.reduce(
    (accumulator: number, item: ICartData) => {
      const realCost = item.service?.is_free
        ? 0
        : Number(item.total_cost_with_discounts);
      return accumulator + realCost;
    },
    0
  ) as number;

  useEffect(() => {
    cartStore.loadCart();
  }, []);

  const isEmpty = !activeCart || !activeCart?.services_count;
  const cartId = activeCart?.id;
  const isMobile = useWindowWidth().isSmallLaptop;
  const isTablet = useWindowWidth().isMediaTablet;
  const buttonRef = useRef<HTMLButtonElement>(null);

  const withRecommendServices =
    projectServices
      ?.filter((item: ICartData) => !!item.service?.recommended_service?.length)
      ?.map((filteredItem: ICartData) => filteredItem?.service?.id) || [];

  const [cookies, setCookies] = useCookies(['access', 'refresh']);
  const [coupon, setCoupon] = useState(activeCart?.coupon || '');
  const [couponError, setCouponError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showFixedButton, setShowFixedButton] = useState(false);

  const bottomMenu = document.querySelector(
    `.${headerClasses['header__bottom-menu']}`
  ) as HTMLElement;

  useEffect(() => {
    if (couponError) return;
    setErrorMessage('');
  }, [couponError]);

  const applyCouponHandler = async (coupon_input: string) => {
    try {
      const response = await ApplyCoupon({
        coupon: coupon_input,
        access: cookies.access,
        totalSelectedItemsAmount: Math.floor(totalWithDiscounts),
      });
      if (response?.updated === true) {
        setCoupon(coupon_input);
      } else {
        setCouponError(true);
      }
      cartStore.loadCart();
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        setErrorMessage(error.response?.data?.coupon[0]);
        setCouponError(true);
      }
    }
  };

  const withdrawCouponHandler = async (remove?: boolean) => {
    const response = await WithdrawCoupon({
      access: cookies.access,
    });
    if (response?.updated === true) {
      if (!remove && coupon) await applyCouponHandler(coupon);
      setCoupon('');
    } else {
      setCouponError(true);
    }
    cartStore.loadCart();
  };

  const onCloseDealModal = () => {
    cartStore.loadCart();
    setShowModal(false);
  };

  const selectedServiceIds = new Set(
    selectedItems?.map((item: ICartData) => item.service.id)
  );

  const projectServiceIds: number[] = [];

  for (const projectService of projectServices) {
    const serviceId = projectService.service.id;

    if (selectedServiceIds.has(serviceId)) {
      projectServiceIds.push(projectService.id);
    }
  }

  const onCreateDeal = async () => {
    try {
      await cartStore.createDeal({
        serviceSourceIds: projectServiceIds,
      });

      setShowModal(true);
      cartStore.loadCart();
      setCoupon('');
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        if (err.response?.data?.service_project_id) {
          ShowNotification({
            type: 'error',
            children: err.response?.data?.service_project_id[0],
          });
        }
      }
    }
  };

  
  const fixedBtnHeight = (height: number) => {
    cartStore.setFixedButtonSize(height);
  };

  const isInViewport = (element: HTMLElement) => {
    const rect = element.getBoundingClientRect();

    const result =
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth);

    setShowFixedButton(!result);
  };

  useEffect(() => {
    if (!bottomMenu) return;
    fixedBtnHeight(56);

    if (selectedItems?.length) {
      setShowFixedButton(true);
    } else {
      setShowFixedButton(false);
    }

    window.addEventListener('scroll', () => {
      if (buttonRef?.current) {
        isInViewport(buttonRef.current as HTMLElement);
      }
    });

    return () => {
      window.removeEventListener('scroll', () => {
        if (buttonRef?.current) {
          isInViewport(buttonRef?.current as HTMLElement);
        }
      });
    };
  }, [buttonRef, bottomMenu, selectedItems?.length]);

  const isShowFixedButton =
    showFixedButton &&
    selectedItems.length > 0 &&
    isTablet &&
    selectedItems?.length > 0;

  return {
    t,
    isMobile,
    isEmpty,
    data,
    count,
    selectedItems,
    cartId,
    setCoupon,
    applyCouponHandler,
    onCreateDeal,
    totalWithDiscounts,
    total,
    coupon,
    cartStore,
    onCloseDealModal,
    showModal,
    isShowFixedButton,
    withRecommendServices,
    couponError,
    buttonRef,
    withdrawCouponHandler,
    errorMessage,
    setCouponError,
  };
};
