import clsx from 'clsx';
import { Grid, GridItem } from 'components/common/Grid';
import { LABELS } from 'constants/onboarding';
import { useUserStore } from 'contexts/UserContext';
import { t } from 'i18next';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { RUSSIAN_LOCALE } from 'utils';
import { useWindowWidth } from 'hooks/useWindowWidth';
import '../CardsContainer/IntroTooltip.css';
import classes from './Account.module.scss';
import { Business } from './Business';
import { ChangePassword } from './ChangePassword';
import { Personal } from './Personal';
import { Switch } from './Switch';
import {
  completeOnboarding,
  findOnboardingSteps,
  formatSteps,
  OnboardingSteps,
} from 'tools/onboarding';

export const AccountContainer = observer(({ section }: { section: string }) => {
  const { GOT_IT, NEXT, BACK } = LABELS(t);
  const isMobile = useWindowWidth().isMediaTablet;
  const userStore = useUserStore();
  const user = userStore.user;

  const [currentStep, setCurrentStep] = useState(0);
  const [onboarding, setOnboarding] = useState<OnboardingSteps | null>(null);
  const onboardingPath = 'PROFILE';

  const hasCompletedOnboarding = useRef(false);

  useEffect(() => {
    findOnboardingSteps('PROFILE').then((res) => setOnboarding(res));
  }, []);

  const completeCurrentOnboarding = useCallback(() => {
    if (hasCompletedOnboarding.current) {
      return;
    }

    completeOnboarding(onboarding?.id);
    setOnboarding(null);
    hasCompletedOnboarding.current = true;
  }, [onboarding?.id]);

  const getParams = useCallback(async () => {
    userStore.loadUser();
  }, []);

  const personalData = {
    fullName: user.full_name,
    email: user.email,
    phone: user.phone_number ? user.phone_number.substring(1) : '',
  };

  const businessData = {
    companyName: user.company_name,
    companyLink: user.company_link,
    companySize: user.company_size,
    businessType: user.company_industry,
  };

  return (
    <>
      {!isMobile && onboarding ? (
        <Steps
          enabled={true}
          steps={formatSteps(onboardingPath, onboarding.steps)}
          options={{
            tooltipClass: 'customTooltip',
            showBullets: true,
            doneLabel: GOT_IT,
            nextLabel: NEXT,
            prevLabel: BACK,
          }}
          initialStep={0}
          onChange={(step: number) => {
            setCurrentStep(step);
          }}
          onExit={completeCurrentOnboarding}
        />
      ) : null}
      <div className={classes.wrapper}>
        <Grid direction={'row'} className={classes.container}>
          <GridItem col={{ default: 12, xsup: 4, md: 3 }}>
            <Switch
              className={clsx(section && 'chosen')}
              section={currentStep ? '' : section}
              currentStep={currentStep}
            />
          </GridItem>

          <GridItem col={{ default: 12, xsup: 8, md: 6 }}>
            {section && (
              <div
                className={classes.inner}
                data-step-profile-1
                data-step-profile-2
                data-step-profile-3
              >
                {(currentStep === 1 ||
                  (section === 'personal' && personalData && !currentStep)) && (
                  <Personal defaultValue={personalData} getParams={getParams} />
                )}
                {(currentStep === 2 ||
                  (section === 'business' && businessData && !currentStep)) && (
                  <Business defaultValue={businessData} getParams={getParams} />
                )}
                {(currentStep === 3 ||
                  currentStep === 4 ||
                  (section === 'change-password' && !currentStep)) && (
                  <ChangePassword />
                )}
              </div>
            )}
          </GridItem>
        </Grid>
      </div>
    </>
  );
});
