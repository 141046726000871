import 'styles/main.scss';
import { DashboardContainer } from 'containers/DashboardContainer';
import { useTranslation } from 'react-i18next';
import { useUserStore } from 'contexts/UserContext';
import { NotFoundPage } from './NotFoundPage';

export const DashboardPage = () => {
  const { t } = useTranslation();
  const userStore = useUserStore();
  const { is_staff } = userStore?.user || {};

  return is_staff ? (
    <DashboardContainer title={t('Dashboard')} />
  ) : (
    <NotFoundPage />
  );
};
