import { Request } from 'tools/request';

interface Props {
  access: string;
  refresh: string;
  projectID: string;
  workspaceId: string;
}

export async function GetDashboardParams({
  access,
  refresh,
  projectID,
  workspaceId,
}: Props) {
  const result = await Request({
    type: 'GET',
    url: `${process.env.REACT_APP_API}/api/v1/dashboards/params/?project_id=${projectID}&workspace_id=${workspaceId}`,
    isProtected: true,
  });

  return result;
}
