import React from 'react';
import { Button } from 'components/shared/Button';
import { FeedbackModal } from 'components/shared/FeedbackModal';
import { CrossIcon } from 'components/shared/Icons/CrossIcon';
import { Textarea } from 'components/shared/Input/Textarea';
import { Logo } from 'components/common/Logo';
import { RUSSIAN_LOCALE } from 'utils';
import classes from './CardManager.module.scss';
import { CardManagerProps } from './CardManager.props';
import { useCardManager } from './useCardManager';
import defaultImageBlue from 'assets/img/AvatarBlue.svg';
import defaultImageRed from 'assets/img/AvatarRed.svg';

export const CardManager = ({
  name,
  src,
  alt,
  serviceId,
}: CardManagerProps) => {
  const {
    t,
    setOpenModal,
    confirmFeedback,
    closeModal,
    openModal,
    questionValue,
    bigQuestionLength,
    setQuestionValue,
    questionErrorMessage,
    setSourceValue,
    sourceErrorMessage,
    sourceValue,
    sendRequest,
    disabled,
    bigSourceLength,
  } = useCardManager(serviceId as string);

  const defaultImage = RUSSIAN_LOCALE ? defaultImageRed : defaultImageBlue;
  const imageSrc = src || defaultImage;

  return (
    <>
      <div className={classes.container} data-step-solutions_details-1>
        <img
          className={classes.image}
          src={imageSrc}
          alt={alt || 'default avatar'}
        />
        <h4 className={classes.title}>
          {t('Hi! My name is {{name}}', { name: name })}
        </h4>
        <p className={classes.text}>
          {t(
            'I am ready to help you to make the right choice. Feel free to ask me any questions about this solution'
          )}
        </p>

        <Button
          onClick={(e) => {
            e.preventDefault();
            setOpenModal(true);
          }}
          theme="text"
          className={classes.button}
        >
          {t('Get in touch')}
        </Button>
      </div>

      <FeedbackModal isOpen={openModal} onClose={closeModal}>
        <div className={classes.modal}>
          {!confirmFeedback ? (
            <>
              <div className={classes.header}>
                <div className={classes.wrapper}>
                  <Logo />
                  <div
                    role="button"
                    onClick={closeModal}
                    className={classes.close}
                  >
                    <CrossIcon />
                  </div>
                </div>
              </div>

              <h4 className={classes.modalTitle}>
                {t('Request for a consultation')}
              </h4>

              <div className={classes.modalBlock}>
                <div className={classes.textareaCustomBig}>
                  <Textarea
                    label={t('Your question')}
                    value={questionValue}
                    error={questionErrorMessage}
                    placeholder={t(
                      'What would you like to know about this solution'
                    )}
                    onChange={setQuestionValue}
                    maxLength={700}
                  />
                  {bigQuestionLength ? (
                    <p className={classes.error}>{questionErrorMessage}</p>
                  ) : null}
                </div>

                <div className={classes.textareaCustomNormal}>
                  <Textarea
                    label={t('Source (optional)')}
                    value={sourceValue}
                    error={sourceErrorMessage}
                    placeholder={t('Link or a source description')}
                    onChange={setSourceValue}
                    maxLength={100}
                  />
                  {bigSourceLength ? (
                    <p className={classes.error}>{sourceErrorMessage}</p>
                  ) : null}
                </div>

                <p className={classes.policy}>
                  {t(
                    'By applying this form You further agree on the terms of our'
                  )}{' '}
                  <a
                    className={classes['policy__link']}
                    rel="noreferrer"
                    href={
                      RUSSIAN_LOCALE
                        ? process.env.REACT_APP_PRIVACY_POLICY_URL
                        : 'https://kit.global/privacy-policy/'
                    }
                    target="_blank"
                  >
                    {t('Privacy Policy')}
                  </a>
                </p>
              </div>

              <Button
                className={classes.modalButton}
                theme="text"
                disabled={disabled}
                onClick={sendRequest}
              >
                {t('Send request')}
              </Button>
            </>
          ) : (
            <div className={classes.modalFeedbackBlock}>
              <div className={classes.modalFeedbackImage} />
              <h4 className={classes.modalFeedbackTitle}>
                {t('Request sent')}
              </h4>
              <p className={classes.modalFeedbackText}>
                {t(
                  'Thank you, we’ll go back to you with more information within 2 working days'
                )}
              </p>

              <Button
                theme="primary"
                className={classes.modalFeedbackButton}
                onClick={closeModal}
              >
                {t('Done')}
              </Button>
            </div>
          )}
        </div>
      </FeedbackModal>
    </>
  );
};
