import classes from './Toast.module.scss';
import clsx from 'clsx';
import { CheckCircleIcon } from 'components/shared/Icons/CheckCirleIcon';
import { UnionIcon } from 'components/shared/Icons/UnionIcon';
import { InfoIcon } from 'components/shared/Icons/InfoIcon';
import { ToastProps } from './Toast.props';

export const Toast = ({
  type,
  children,
  toastClass,
  childrenClass,
  toastIconClass,
}: ToastProps) => (
  <div className={clsx(classes['toast-container'], classes[type], toastClass)}>
    <div className={clsx(classes.toast, classes[type], toastClass)}>
      <div className={clsx(classes.icon, toastIconClass)}>
        {type === 'success' && (
          <CheckCircleIcon fill={'#00C853'} width={'100%'} height={'100%'} />
        )}

        {type === 'warning' && (
          <InfoIcon fill={'#F3CA37'} width={'100%'} height={'100%'} />
        )}

        {type === 'error' && (
          <UnionIcon fill={'#F5222D'} width={'100%'} height={'100%'} />
        )}
      </div>

      <span className={clsx(classes.text, childrenClass)}>{children}</span>
    </div>
  </div>
);
