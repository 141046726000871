import { useState, useEffect } from 'react';
import classes from './Switch.module.scss';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { ShapeGradientIcon } from 'components/shared/Icons/ShapeGradientIcon';
import { WalletGradientIcon } from 'components/shared/Icons/WalletGradientIcon';
import { LockGradientIcon } from 'components/shared/Icons/LockGradientIcon';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { routers } from 'constants/routers';
import { useTranslation } from 'react-i18next';
import { RUSSIAN_LOCALE } from 'utils';
import { LeftArrowIcon } from 'components/shared/Icons/LeftArrowIcon';
import { SwitchProps } from './Switch.props';
import SwitchNavItem from './SwitchNavItem/SwitchNavItem';

export const Switch = ({
  className: auditionalClassname,
  section,
  currentStep,
}: SwitchProps) => {
  const { t } = useTranslation();

  const { isMediaTablet, isSmallLaptop } = useWindowWidth();
  const { origin, pathname } = window.location;
  const [open, setOpen] = useState(false);

  const isLinkAccount =
    pathname === `${routers.personal}` ||
    pathname === `${routers.business}` ||
    pathname === `${routers.changePassword}`;

  useEffect(() => {
    if (isMediaTablet) {
      if (
        pathname !== `${routers.personal}` &&
        pathname !== `${routers.business}` &&
        pathname !== `${routers.changePassword}`
      ) {
        setOpen(false);
      } else {
        setOpen(true);
      }
    }
  }, [pathname]);

  return (
    <div
      className={clsx(
        classes.wrapper,
        auditionalClassname && classes[auditionalClassname]
      )}
    >
      {isMediaTablet && (
        <div className={classes.mobileTitle}>{t('Account')}</div>
      )}

      <div className={classes.list}>
        <SwitchNavItem
          to={section && !isSmallLaptop ? '/account' : '/account/personal'}
          isActive={currentStep === 1 || section === 'personal'}
          icon={<ShapeGradientIcon />}
          label={t('Personal information')}
          isHidden={isLinkAccount}
        />

        <SwitchNavItem
          to={section && !isSmallLaptop ? '/account' : '/account/business'}
          isActive={currentStep === 2 || section === 'business'}
          icon={<WalletGradientIcon />}
          label={t('Business information')}
          isHidden={isLinkAccount}
        />

        {!RUSSIAN_LOCALE && (
          <SwitchNavItem
            to={
              section && !isSmallLaptop
                ? '/account'
                : '/account/change-password'
            }
            isActive={currentStep === 3 || section === 'change-password'}
            icon={<LockGradientIcon />}
            label={t('Change password')}
            isHidden={isLinkAccount}
          />
        )}
      </div>

      {!isSmallLaptop && isLinkAccount && (
        <NavLink to={'/account'} className={classes['link-back']}>
          <LeftArrowIcon className={classes['link-back__arrow']} />
          <span className={classes.backTo}>{t('Back to Account')}</span>
        </NavLink>
      )}
    </div>
  );
};
