import { makeAutoObservable, runInAction } from 'mobx';
import User from 'services/user';
import i18n from 'i18n/i18n';
import { QueryParamTypes } from '../constants/queryParamTypes';
import { RUSSIAN_LOCALE } from '../utils';
import { AxiosError } from 'axios';
import { ProjectInvoice, ProjectProps } from '../types/project';
import { GetDashboardProjects } from 'services/api/getDashboardProjects';
import { initOnboarding } from 'tools/onboarding';

export interface userProps {
  user: any;
  projects: any;
  invoices: any;
  projectInvoices: ProjectInvoice;
  projectData: any;
  addUser: (val: any) => void;
  loadUser: () => any;
  loadInvoices: (params: {}) => any;
  loadProjectInvoices: (currentPage: number, projectId: number) => any;
  sendRegistrationCompleteEmail: () => void;
  changeLanguage: (language: string) => void;
  clearStore: () => void;
  pagesToOnboard: any;
  isInnerDataLoading: boolean;
  isDataLoading: boolean;
  isComplete: boolean;
  localeChanged: boolean;
  setLocaleChanged: (val: boolean) => void;
  isProjectError: boolean;
  isPermissionError: boolean;
  isLocaleError: boolean;
  dashboardsProjectsLength: number;
  allOnboardings: any;
  loadAllOnboardings: () => any;
  onboardingSteps: any;
  loadOnboardingSteps: (id: string) => any;
}

class UserStore {
  user = {} as any;
  projects = [];
  invoices = [];
  projectInvoices = {} as ProjectInvoice;
  isComplete = false;
  projectData: ProjectProps | undefined | {} = {};
  isDataLoading = false;
  isInnerDataLoading = false;
  currentLanguage = i18n.language;
  onboarding_client_passed = false;
  onBoardingComplete = false;
  pagesToOnboard = {
    solutions: false,
    solutionsDetail: false,
    projects: false,
    finances: false,
    profile: false,
    cart: false,
    locale: false,
  };
  localeChanged = false;
  isProjectError = false;
  isPermissionError = false;
  isLocaleError = false;
  dashboardsProjectsLength = 0;
  allOnboardings = [];
  onboardingSteps = [];
  constructor() {
    makeAutoObservable(this);
  }

  async loadAllOnboardings() {
    try {
      const response = await User.getAllOnboardings();
      runInAction(() => {
        this.allOnboardings = response?.data;
      });
      return response?.data;
    } catch (error) {
      console.error('Ошибка при загрузке списка онбордингов:', error);
      return null;
    }
  }

  async loadOnboardingSteps(id: string) {
    try {
      const response = await User.getOnboardingSteps(id);
      runInAction(() => {
        this.onboardingSteps = response?.data;
      });
      return response?.data;
    } catch (error) {
      console.error('Ошибка при загрузке шагов онбординга:', error);
      return null;
    }
  }

  async completeOnboarding(onboardingId: string) {
    try {
      const response = await User.completeOnboarding(onboardingId);
      return response?.data;
    } catch (error) {
      console.error('Ошибка при выполнении шагов онбординга:', error);
      return null;
    }
  }

  addUser(user: any) {
    this.user = user;
  }

  addDashboardsProjectsLength(dashboardsProjectsLength: number) {
    this.dashboardsProjectsLength = dashboardsProjectsLength;
  }

  async loadUser() {
    try {
      const response = await User.getUser();
      this.addUser(response?.data);
      const userLocale: string | null =
        response?.data?.language?.lang_code || null;
      const userOffice: string | null =
        response?.data?.user_office?.name || null;

      if (RUSSIAN_LOCALE) {
        this.currentLanguage = 'ru';
        if (userOffice !== null && userOffice !== 'Kokoc Group') {
          this.isLocaleError = true;
          this.currentLanguage = userLocale || 'ru';
        }
        await this.changeLanguage(this.currentLanguage);
      } else {
        if (userOffice !== null && userOffice === 'Kokoc Group') {
          this.isLocaleError = true;
          this.currentLanguage = userLocale || 'en';
          await i18n.changeLanguage(this.currentLanguage);
        } else {
          this.currentLanguage = userLocale || 'en';
          await this.changeLanguage(this.currentLanguage);
        }
      }
      if (!RUSSIAN_LOCALE && i18n.language !== this.currentLanguage) {
        await this.changeLanguage(this.currentLanguage);
      }
      // this.onboarding_client_passed = response?.data?.onboarding_client_passed;

      if (response?.data) {
        runInAction(() => {
          initOnboarding();
          this.isComplete = true;
        });
      }

      return response?.data;
    } catch (error: any) {
      if ((error as AxiosError)?.response?.status === 403) {
        const userLocale: string | null =
          error?.response?.data?.language?.lang_code || null;
        runInAction(async () => {
          this.isPermissionError = true;
          this.currentLanguage = userLocale || 'en';
          await i18n.changeLanguage(this.currentLanguage);
        });

        throw error;
      }
      return error;
    }
  }

  async loadDashboardProjects() {
    try {
      const response = await GetDashboardProjects();
      this.addDashboardsProjectsLength(response?.results?.length);
      return response?.results;
    } catch (error) {
      console.log(error, 'Get DashboardParams Error');
      return error;
    }
  }

  async sendRegistrationCompleteEmail() {
    const response = await User.sendRegistrationCompleteEmail();
    return response.data;
  }

  setLocaleChanged = (val: boolean) => {
    this.localeChanged = val;
  };

  async changeLanguage(language: string) {
    try {
      const response = await User.changeLanguage(language);
      if (response.data.is_valid === true) {
        await i18n.changeLanguage(language);
        this.addUser(response.data.user);
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  setProjects(projects: any) {
    this.projects = projects;
  }

  setProjectsError(error: boolean) {
    this.isProjectError = error;
  }

  async loadProjects(reload?: any) {
    if (reload?.reload) {
      this.loadData(undefined, true);
    }
    const response = await User.getProjects();
    this.setProjects(response?.data);
    this.loadData(undefined, false);
    return response?.data;
  }

  setFinances(finances: any) {
    this.invoices = finances;
  }

  setProjectInvoices(projectFinance: ProjectInvoice) {
    this.projectInvoices = projectFinance;
  }

  async loadInvoices(params?: QueryParamTypes) {
    const response = await User.getInvoices(params);
    this.setFinances(response?.data);
    return response?.data;
  }

  async loadProjectInvoices(page: number = 1, projectId: number) {
    const response = await User.getProjectInvoices(page, projectId);
    if (response?.status === 404) {
      this.setProjectsError(true);
    } else {
      this.setProjectInvoices(response?.data);
      this.setProjectsError(false);
      return response?.data;
    }
  }

  async loadProjectData(projectId: number) {
    const response = await User.getProject(projectId);
    if (response?.status === 404) {
      this.setProjectsError(true);
    } else {
      this.projectData = response?.data;
      this.setProjectsError(false);
    }
  }

  loadData(isDataLoading?: boolean, isInnerDataLoading?: boolean) {
    if (isDataLoading !== undefined) {
      this.isDataLoading = isDataLoading;
    }
    if (isInnerDataLoading !== undefined) {
      this.isInnerDataLoading = isInnerDataLoading;
    }
  }

  clearStore() {
    this.user = {};
    this.projects = [];
    this.invoices = [];
    this.projectInvoices = {} as ProjectInvoice;
    this.isComplete = false;
    this.projectData = {};
    this.dashboardsProjectsLength = 0;
  }
}

export const userStore = new UserStore();
