import * as React from 'react';
import { SVGProps } from 'react';

export const QuestionIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.57508 8.00002C7.771 7.44308 8.15771 6.97344 8.66671 6.6743C9.17571 6.37515 9.77416 6.2658 10.3561 6.36561C10.938 6.46543 11.4658 6.76796 11.846 7.21963C12.2262 7.6713 12.4343 8.24296 12.4334 8.83335C12.4334 10.5 9.93342 11.3334 9.93342 11.3334M10.0001 14.6667H10.0084M18.3334 10.5C18.3334 15.1024 14.6025 18.8334 10.0001 18.8334C5.39771 18.8334 1.66675 15.1024 1.66675 10.5C1.66675 5.89765 5.39771 2.16669 10.0001 2.16669C14.6025 2.16669 18.3334 5.89765 18.3334 10.5Z"
      stroke="#C4BBD2"
      strokeWidth="1.5"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);
