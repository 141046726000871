import clsx from 'clsx';
import classes from './Link.module.scss';
import { Link as BaseLink } from 'react-router-dom';
import { LinkProps } from './Link.props';

export const Link = ({
  children,
  href,
  className,
  isEmpty = false,
  target,
  onClick,
}: LinkProps) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (onClick) {
      e.preventDefault();
      onClick();
    }
  };

  return (
    <div className={clsx(classes.wrapper, isEmpty && classes.empty, className)}>
      <BaseLink to={href} target={target} onClick={handleClick}>
        {children}
      </BaseLink>
    </div>
  );
};
